<template>
  <div>
    <spinner-loader :loading="loading" />
    <azkar-form
      v-if="!loading"
      :item="azkar"
      :disabled="true"
    />
  </div>
</template>
<script>
import AzkarForm from '@/common/components/azkar/AzkarForm.vue'
import Azkar from '@/common/compositions/azkar/AzkarApi'
import SpinnerLoader from '@/common/components/common/Table/SpinnerLoader.vue'

export default {
  name: 'ViewZikr',
  components: { AzkarForm, SpinnerLoader },
  data() {
    return {
      loading: true,
    }
  },
  setup() {
    const {
      azkar, getAzkarRequest, prepareFormData,
    } = Azkar()

    return {
      azkar, getAzkarRequest, prepareFormData,
    }
  },
  created() {
    this.getAzkarRequest(this.$route.params.id).then(res => {
      this.azkar = {
        ...res.data.data,
      }
    }).finally(() => {
      this.loading = false
    })
  },
}
</script>
<style lang="">

</style>
